@import "../../global.css";

header {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

header img.logo {
  height: 75px;
}

header img.rosaParks {
  max-width: 80px;
  height: 80px;
  width: 100%;
}

.containerInfo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 881px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  color: var(--black);
}

.containerInfo>img {
  height: 90px;
}

.containerInfo h2 {
  font-size: 2.5rem;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  font-family: 'DDinPro700', sans-serif;
}

.containerInfo p {
  text-align: center;
  font-family: 'DDinPro400', sans-serif;
  max-width: 690px;
  width: 100%;
  font-size: 2rem;
  margin-top: 20px;

}

.containerInfo ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 40px;
}

.containerInfo li img {
  max-width: 90px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .containerInfo h2 {
    font-size: 4rem;
  }

  .containerInfo p {
    font-size: 2rem;
  }
  
  .containerInfo li img {
    max-width: 120px;
    cursor: pointer
  }
}
