@import url('global.css');

@font-face {
  font-family: 'DDinPro400';
  src: local('DDinPro400'), url(./fonts/DDinPro/D-DIN-PRO-400-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'DDinPro500';
  src: local('DDinPro500'), url(./fonts/DDinPro/D-DIN-PRO-500-Medium.otf) format('opentype');
}


@font-face {
  font-family: 'DDinPro700';
  src: local('DDinPro700'), url(./fonts/DDinPro/D-DIN-PRO-700-Bold.otf) format('opentype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DDinPro400';
}

html, body, #root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.mainContainer {
  height: 100vh;
  width: 100vw;
  background-color: var(--light-blue);
  overflow: hidden;
}